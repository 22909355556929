import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export const useLandingHeadline = makeStyles({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.light,
  },

  listItemText: {
    paddingLeft: theme.spacing(2),
  },
});

export const useReportBugHeadline = makeStyles({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },

  listItemText: {
    paddingLeft: theme.spacing(2),
  },

  linkNoStyle: {
    color: theme.palette.logo.blue,
  },
});

export const useIconOverRide = makeStyles(() => ({
  root: {
    flex: 'none !important',
  },
}));

export const useRegistration = makeStyles({
  actionsMessageContent: {
    marginBottom: theme.spacing(2),
  },

  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.light,
  },
});
